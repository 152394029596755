import React from 'react';
import Scroll from '../components/Scroll';

import Layout from '../components/Layout';
const GetQuote = () => (
  <Layout>
    <article id="main">
      <header>
        <h1>Moving made easy</h1>
        <p>We lay it our for you from A to Z</p>
          <Scroll type="id" element="end">
            <a href="#moving-quote" className="button primary">
              Get quote
            </a>
          </Scroll>
      </header>
    </article>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2> Moving as easy as 1, 2, 3</h2>
          <p>
            How do you hire movers and make sure that the move goes well and the
            movers you hired are the best movers for you? In 4 simple steps one
            after the other.
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-truck">
            <h3>Moving quote step 1</h3>
            <p>
              In order to provide you with a moving quote we need to know what
              will be going on the moving truck. The more detail we have of what
              will be moving the greater the accuracy of your quote. The best
              way to get a quote will be for you to put in your contact info
              below and allow us a few minutes to speak with you.
            </p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>Moving quote step 2</h3>
            <p>
              Your long distance moving agent will call you and discuss the
              details of your move and provide you with an estimate based on the
              contents of your home. We can provide you with whatever level of
              service you require which within reason. Amongst the things we are
              happy to handle for you are piano moving, auto transport, moving
              pool tables, and moving safes. If you are a business owner we also
              handle commercial moving and can put together a cross country
              moving package for you and your employees.
            </p>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Moving quote step 3</h3>
            <p>
              What’s the next step once you have a moving quote? As long as this
              estimate is something that you feel is reasonable and you can see
              yourself working with us then the next step is to get an in home
              estimate. Why get on site estimate?
            </p>
          </li>
          <li className="icon solid fa-home">
            <h3>Moving quote step 4</h3>
            <p>
              Because our primary desire is for you to be happy with your move,
              we perform in home estimates whenever possible. In general we only
              perform in home estimate for moves of a certain size. Without an
              in home estimate you never know exactly what your move will
              actually cost. The benefit of getting an in home estimate is that
              you will have a guaranteed price for your move. According to the
              Department of Transportation it is in your best interest to only
              use a moving company who can provide you with an in home estimate
              and as movers who have been in the moving business for a long time
              we couldn’t agree more.
            </p>
          </li>
        </ul>
      </div>
    </section>
<div id="end" />



  </Layout>
);
export default GetQuote;
